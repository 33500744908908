






























































































































































































































































































.mr15 {
  margin-right: 15px;
}

.mt15 {
  margin-top: 15px;
}

.CheckInTimeTitle {
  font-size: 12px;
  color: #606266;
  margin-right: 5px;
}

.card-slot-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
